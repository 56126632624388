@import "~node_modules/@abridge/web-ui/scss/constants";
@import "~node_modules/@abridge/web-ui/scss/animations";

.abridge-otp-input-container {
  display: flex;
  flex-direction: row;
}

.abridge-otp-input-main {
  font: var(--subtitle-large);
  border: none;
  background: transparent;
  border-bottom: 1px solid var(--stroke-primary);
  caret-color: var(--text-primary);
  color: var(--text-primary);
  height: 30px;
  line-height: 28px;
  margin: 0 4px;
  padding: 0;
  width: 42px !important;
}

.abridge-otp-input-focus {
  outline: none;
}

.abridge-otp-input-disabled {
  opacity: 0.5;
}

.abridge-otp-input-error {
  border-bottom: 1px dashed $red;
}

@media only screen and (min-width: 768px) {
}
