@font-face {
  font-family: "Avantt-Regular";
  src: local("Avantt-Regular"),
    url(/fonts/Avantt-Regular.ttf) format("truetype");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avantt-Regular";
  src: local("Avantt-Regular"),
    url(/fonts/Avantt-SemiBold.ttf) format("truetype");
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "ABRIDGE-Display";
  src: local("ABRIDGE-Display"),
    url(/fonts/ABRIDGE-Display.otf) format("truetype");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

:root {
  /* Scale */
  --scale-0: 0px;
  --scale-1: 4px;
  --scale-2: 8px;
  --scale-3: 12px;
  --scale-4: 16px;
  --scale-5: 20px;
  --scale-6: 24px;
  --scale-8: 32px;
  --scale-10: 40px;
  --scale-12: 48px;
  --scale-14: 56px;
  --scale-16: 64px;
  --scale-18: 72px;
  --scale-20: 80px;

  /* Spacing */
  --spacing-none: var(--scale-0);
  --spacing-xxs: var(--scale-1);
  --spacing-xs: var(--scale-2);
  --spacing-xs-alt: var(--scale-3);
  --spacing-sm: var(--scale-4);
  --spacing-md: var(--scale-6);
  --spacing-lg: var(--scale-8);
  --spacing-xl: var(--scale-10);
  --spacing-xxl: var(--scale-12);
  --spacing-xxxl: var(--scale-14);

  /* Colors */

  --red-10: #ffede9;
  --red-20: #ffd5cc;
  --red-30: #ffa996;
  --red-40: #ff8469;
  --red-50: #ff5832;
  --red-55: #ea2c00;
  --red-60: #c02907;
  --red-70: #991c00;
  --red-80: #6c1400;
  --red-90: #440c00;
  --red-100: #260700;

  --blue-10: #ecf3fd;
  --blue-20: #d0e1fb;
  --blue-30: #9cc0f7;
  --blue-40: #76a8f4;
  --blue-50: #3e85ef;
  --blue-55: #136aec;
  --blue-60: #0e4eaf;
  --blue-70: #0a397f;
  --blue-80: #082a5d;
  --blue-90: #051e42;
  --blue-100: #04152f;

  --teal-5: #e6f1ef;
  --teal-10: #cce3df;
  --teal-20: #b3d5cf;
  --teal-30: #81baaf;
  --teal-40: #4e9e8f;
  --teal-50: #1b826f;
  --teal-55: #02745f;
  --teal-60: #015143;
  --teal-70: #013a30;
  --teal-80: #012e26;
  --teal-90: #01231d;
  --teal-100: #001713;

  --grey-5: #fcfaf8;
  --grey-10: #f7f2ed;
  --grey-20: #eee5dd;
  --grey-30: #d5cbc0;
  --grey-40: #c0b4a8;
  --grey-50: #a7988a;
  --grey-55: #837567;
  --grey-60: #6d645a;
  --grey-70: #534d46;
  --grey-80: #393633;
  --grey-90: #242220;
  --grey-100: #141312;

  --error-10: #ffe9ed;
  --error-20: #ffccd5;
  --error-30: #ff96a9;
  --error-40: #ff6984;
  --error-50: #ff3258;
  --error-55: #eb002b;
  --error-60: #c00729;
  --error-70: #99001c;
  --error-80: #6c0014;
  --error-90: #44000c;
  --error-100: #260007;

  --warning-10: #fff4d4;
  --warning-20: #ffe39e;
  --warning-30: #ffd36e;
  --warning-40: #ffc522;
  --warning-50: #f3a600;
  --warning-55: #d68400;
  --warning-60: #9e6400;
  --warning-70: #724800;
  --warning-80: #593800;
  --warning-90: #382300;
  --warning-100: #211700;

  --success-10: #e1f4df;
  --success-20: #b8e8b3;
  --success-30: #7edb71;
  --success-40: #4dcc3f;
  --success-50: #12b000;
  --success-55: #109900;
  --success-60: #097900;
  --success-70: #075900;
  --success-80: #063f00;
  --success-90: #042b00;
  --success-100: #021c00;

  /* Success Colors */
  --success-50: #12b000;

  /* Foundation Colors */
  --white: #ffffff;
  --black: #000000;

  /* Brand Colors */
  --brand-primary: var(--red-55);
  --brand-secondary: var(--blue-40);
  --brand-tertiary: var(--grey-30);

  /* Background Colors */
  --bg-primary: var(--white);
  --bg-secondary: var(--grey-5);
  --bg-tertiary: var(--grey-10);
  --bg-accent: var(--blue-40);
  --bg-accent-light: var(--grey-20);
  --bg-accent-bold: var(--black);
  --bg-highlight: var(--teal-10);
  --bg-brand: var(--brand-primary);
  --bg-status-error-light: var(--error-10);
  --bg-inverted: var(--black);

  /* Text Colors */
  --text-primary: var(--black);
  --text-secondary: var(--grey-60);
  --text-tertiary: var(--grey-50);
  --text-accent: var(--blue-40);
  --text-inverted: var(--white);
  --text-brand: var(--brand-primary);
  --text-error: var(--error-50);
  --text-accent-bold: var(--red-55);

  /* Icon Colors */
  --icon-primary: var(--black);
  --icon-secondary: var(--grey-55);
  --icon-tertiary: var(--grey-50);
  --icon-inverted: var(--white);
  --icon-brand: var(--brand-primary);
  --icon-accent: var(--blue-40);
  --icon-accent-bold: var(--red-55);
  --icon-error: var(--error-50);
  --icon-success: var(--success-50);
  --icon-logo: var(--brand-primary);

  /* Stroke Colors */
  --stroke-primary: var(--black);
  --stroke-secondary: var(--grey-40);
  --stroke-secondary-translucent: rgba(192, 180, 168, .2); /* --gray-40 */
  --stroke-tertiary: var(--grey-20);
  --stroke-accent: var(--blue-40);
  --stroke-accent-light: var(--grey-20);
  --stroke-error: rgba(255, 50, 88, .2); /* --error-50 */

  /* Button Colors */
  --button-primary: var(--black);
  --button-secondary: var(--blue-40);
  --button-tertiary: var(--grey-10);
  --button-destructive: var(--red-55);
  --button-inverted: var(--white);

  /* Extented Colors */
  --ext-blue: var(--blue-20);
  --ext-teal: var(--teal-20);
  --ext-teal-pale: var(--teal-5);
  --ext-teal-dark: var(--teal-30);
  --ext-blue-pale: var(--blue-10);
  --ext-blue-med: var(--blue-20);
  --ext-neutral: var(--grey-30);
  --ext-neutral-pale: var(--grey-20);
  --ext-status-alert-medium: var(--warning-20);
  --ext-status-error-medium: var(--error-20);
  --ext-red-medium: var(--error-40);
  --ext-yellow-medium: var(--warning-55);

  --status-alert: var(--warning-50);
  --status-alert-light: var(--warning-10);
  --status-alert-medium: var(--warning-20);
  --status-error: var(--error-50);
  --status-error-light: var(--error-10);
  --status-error-medium: var(--error-20);
  --status-success: var(--success-50);
  --status-success-light: var(--success-10);
  --status-success-medium: var(--success-20);

  /* Radius */
  --radius: var(--scale-1);
  --radius-round: var(--scale-20);

  /* Fonts / Typography */
  --body-small: 400 13px/16px Avantt-Regular;
  --body-medium: 400 15px/20px Avantt-Regular;
  --body-large: 400 16px/20px Avantt-Regular;

  --label-small: 600 13px/16px Avantt-Regular;
  --label-medium: 600 15px/20px Avantt-Regular;

  --title-small: 600 22px/32px Avantt-Regular;
  --title-medium: 600 25px/36px Avantt-Regular;
  --title-large: 600 28px/40px Avantt-Regular;

  --subtitle-small: 600 14px/20px Avantt-Regular;
  --subtitle-medium: 600 18px/24px Avantt-Regular;
  --subtitle-large: 600 20px/28px Avantt-Regular;

  --display-small: 400 28px/28px ABRIDGE-Display;
  --display-medium: 400 32px/32px ABRIDGE-Display;
  --display-large: 400 40px/40px ABRIDGE-Display;

  /* Toastify */
  --toastify-toast-width: 400px;
}

/* Dark mode override */
[data-theme="dark"] {
  --bg-primary: var(--black);
  --bg-secondary: var(--grey-90);
  --bg-tertiary: var(--grey-80);
  --bg-accent-light: var(--grey-70);
  --bg-highlight: var(--teal-60);
  --bg-accent-bold: var(--white);
  --bg-inverted: var(--white);
  --bg-status-error-light: var(--error-80);

  --ext-teal: var(--teal-60);
  --ext-teal-pale: var(--teal-70);
  --ext-teal-dark: var(--teal-50);
  --ext-blue: var(--blue-55);
  --ext-blue-pale: var(--blue-80);
  --ext-blue-med: var(--blue-70);
  --ext-neutral: var(--grey-60);
  --ext-neutral-pale: var(--grey-80);
  --ext-red-medium: var(--error-55);

  --status-alert: var(--warning-60);
  --status-alert-light: var(--warning-80);
  --status-alert-medium: var(--warning-70);
  --status-error-light: var(--error-80);
  --status-error-medium: var(--error-70);
  --status-success-light: var(--success-80);
  --status-success-medium: var(--success-60);

  --text-primary: var(--white);
  --text-secondary: var(--grey-30);
  --text-inverted: var(--black);
  --text-error: var(--error-50);
  --text-accent-bold: var(--red-55);

  --stroke-primary: var(--grey-60);
  --stroke-secondary: var(--grey-70);
  --stroke-secondary-translucent: rgba(83, 77, 70, .2); /* --grey-70 */
  --stroke-tertiary: var(--grey-80);

  --icon-primary: var(--white);
  --icon-secondary: var(--grey-30);
  --icon-tertiary: var(--grey-70);
  --icon-inverted: var(--black);
  --icon-brand: var(--brand-primary);
  --icon-logo: var(--white);
  --icon-accent-bold: var(--red-55);
  --icon-success: var(--success-50);

  --button-primary: var(--white);
  --button-inverted: var(--black);
  /* --button-secondary: var(--blue-40); */
  --button-tertiary: var(--grey-90);
  /* --button-destructive: var(--red-55); */
}
