@import "~node_modules/@abridge/web-ui/scss/constants";
@import "~node_modules/@abridge/web-ui/scss/animations";

@font-face {
  font-family: GascogneTS-Regular;
  src:
    local("GascogneTS-Regular"),
    url(/fonts/GascogneTS-Regular.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "GTAmerica-Regular";
  src:
    local("GTAmerica-Regular"),
    url(/fonts/GTAmerica-Regular.ttf) format("truetype");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GTAmerica-Regular";
  src:
    local("GTAmerica-Medium"),
    url(/fonts/GTAmerica-Medium.ttf) format("truetype");
  font-display: swap;
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "GTAmerica-Regular";
  src:
    local("GTAmerica-Bold"),
    url(/fonts/GTAmerica-Bold.ttf) format("truetype");
  font-display: swap;
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "GTAmerica-Bold";
  src:
    local("GTAmerica-Bold"),
    url(/fonts/GTAmerica-Bold.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "GTAmerica-Medium";
  src:
    local("GTAmerica-Medium"),
    url(/fonts/GTAmerica-Medium.ttf) format("truetype");
  font-display: swap;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-stroke-width: 0 !important;
}

#__next {
  height: 100vh;
}

#app-suspense-loader {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fade-in 0.5s;
}

#app-suspense-loader > div {
  font-family: Avantt-Regular;
  font-size: 24px;
  font-weight: 400;
  color: var(--text-primary);
  margin-top: 1em;
}

// rdt styles
.rdt_TableRow:nth-child(even) {
  background-color: $canvas;
}

.rdt_TableRow:nth-child(odd) {
  background-color: $chalk;
}

.rdt_TableCol {
  font: $bold-big-body !important;
  background-color: $pale-gold !important;
  text-align: center !important;
  font-size: 1em !important;
  padding: 0.8em 0.5em;
}

.rdt_TableCell {
  font: $transcript;
  text-align: center;
}

.rdt_TableHeader > div {
  background-color: $pale-violet !important;
  font: $body !important;
}

.abridge-pl-file-upload-input {
  display: none;
}

.abridge-pl-file-upload-label {
  font: $body;
  border-radius: 50px;
  width: 200px;
  height: 200px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  outline: none;
  color: var(--text-primary);
  background-color: transparent;
  border: 2px solid var(--text-primary);
  cursor: pointer;
  font-size: 14px;
  padding: 0px 0px 0px 5px;
  width: 250px;
  height: 51px;
}

.content-editable {
  outline: none;
  caret-color: var(--text-primary);
  cursor: text;
  white-space: pre-wrap;
  word-break: break-word;
  display: inline-block;
}

// Tooltip

.tooltip > div.tooltip-inner {
  background-color: var(--bg-inverted) !important;
  color: var(--text-inverted) !important;
  font: var(--body-small);
  border-radius: 0;

  opacity: 1;
}

#info-tooltip-primary > .tooltip-inner {
  background-color: var(--bg-inverted) !important;
}

.tooltip::after {
  position: absolute;
  left: 45%;
  top: 5%;
  content: "";
  width: 10px;
  height: 12px;
  transform: rotate(45deg);
}

// medical terms words underline styles

.term-underline-condition {
  border-bottom: 2px solid $violet;
  display: inline-block;
  line-height: 1.5em;
}

.term-underline-medication {
  border-bottom: 2px solid $pink;
  display: inline-block;
  line-height: 1.5em;
}

.term-underline-procedure {
  border-bottom: 2px solid $pale-teal;
  display: inline-block;
  line-height: 1.5em;
}

.term-underline-other {
  border-bottom: 2px solid $pale-grey;
  display: inline-block;
  line-height: 1.5em;
}

.term-underline-labs {
  border-bottom: 2px solid $pale-sky;
  display: inline-block;
  line-height: 1.5em;
}

.highlight-coding-summary-span {
  background-color: $pale-violet;
}

.highlight-los-summary-span {
  background-color: var(--ext-blue-med);
}

.waveform_shapeLayer > path {
  fill: var(--bg-primary) !important;
}

.Toastify__toast {
  background-color: var(--bg-accent-bold);
  border-radius: unset;
  box-shadow: unset;
}

.Toastify__toast--error {
  background-color: var(--status-error-medium);
}

.clickable {
  cursor: pointer;
}

/* Applies additional CSS to make scrollbars appear better in Dark mode */
.abridge-scrollbar {
  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--ext-neutral-pale);
    border-radius: 1px;
    // reduced-opacity
    opacity: 0.2;

    &:hover {
      background: var(--ext-neutral);
      opacity: 0.5;
    }
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}
