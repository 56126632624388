.error-boundary-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  padding: 0 1rem;
}

.error-failed-icon {
  width: 144px !important;
}

.error-header-text {
  font: var(--title-large);
  font-weight: 400;
  position: relative;
  margin-top: 1rem;
}

.error-failed-text {
  text-align: center;
  font: var(--body-large);
  font-weight: 400;
  white-space: wrap;
}

.error-failed-con-sup {
  color: var(--text-accent);
  font-weight: 600;

  a {
    text-decoration: none;
    color: var(--text-accent);
  }
  a:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
