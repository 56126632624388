@import "~node_modules/@abridge/web-ui/scss/constants";
@import "~node_modules/@abridge/web-ui/scss/animations";

.react-datepicker {
  font: var(--body-medium) !important;
  border: none !important;
  width: 340px;
  border-radius: var(--radius);
  background: var(--bg-primary);
  display: flex;
  flex-direction: column;
  color: var(--text-primary);
  border: 1px solid var(--stroke-primary) !important;
  background-color: var(--bg-primary) !important;
}

.react-datepicker-popper {
  z-index: 10;
}

.react-datepicker__header {
  border-bottom: unset;
  background-color: var(--bg-primary) !important;
}

.react-datepicker__month-container {
  width: 100%;
  height: 100%;
  background-color: var(--bg-primary);
}

.react-datepicker__day {
  width: 42px !important;
  text-align: center;
  font: var(--body-medium);
  outline: none;
  color: var(--text-primary) !important;

  &:hover {
    background-color: var(--bg-primary);
    border-radius: unset;
  }

  &.react-datepicker__day--today {
    width: 34px !important;
    height: 34px;
    border: 1px solid var(--bg-brand);
    color: var(--text-primary);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 7px;
  }

  &.react-datepicker__day--selected {
    width: 34px !important;
    border-radius: 0 !important;
    height: 34px;
    background: var(--bg-brand);
    color: var(--bg-primary);
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: var(--bg-brand);
    }
  }

  &.react-datepicker__day--outside-month {
    opacity: 0;
    pointer-events: none;
  }

  &.react-datepicker__day--disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.react-datepicker__week,
.react-datepicker__day-names {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25em;
  height: 42px;
  color: var(--text-primary) !important;
}

.react-datepicker__day-name {
  width: 42px !important;
  color: var(--text-secondary) !important;
  text-align: center;
  font: var(--body-small);
  font-style: normal;
  letter-spacing: -0.078px;
  text-transform: uppercase;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__children-container {
  width: 95%;
  display: flex;
  background-color: var(--bg-primary);
}
